import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import { ButtonPrimary } from "../components/elements/Button";
import { Container } from "../components/elements/Container";
import Section, { SectionParagraph, SectionTitle } from "../components/elements/Section";
import SEO from "../components/elements/SEO";
import Layout from "../components/layout/Layout";
import { Main } from "../components/layout/Main";

const query = graphql`
  query {
    strapiNotFoundPage {
      pageInfo {
        seoTitle
      }
      headline
      description
      action {
        label
        url
      }
    }
  }
`;

function NotFoundPage() {
  const { strapiNotFoundPage } = useStaticQuery(query);
  return (
    <Layout>
      <SEO title={strapiNotFoundPage.pageInfo?.seoTitle} />
      <Main style={{ display: "flex", justifyContent: "center" }}>
        <Section style={{ alignSelf: "center" }}>
          <Container>
            <SectionTitle>{strapiNotFoundPage.headline}</SectionTitle>
            <SectionParagraph>{strapiNotFoundPage.description}</SectionParagraph>
            <Link to={strapiNotFoundPage.action.url}>
              <ButtonPrimary style={{ display: "block", margin: "0 auto" }}>
                {strapiNotFoundPage.action.label}
              </ButtonPrimary>
            </Link>
          </Container>
        </Section>
      </Main>
    </Layout>
  );
}

export default NotFoundPage;
